/*
 * @Author: 王松
 * @Descripttion:
 * @Date: 2022-12-08 10:14:53
 */
import loginApi, * as loginKey from './login'
import mockApi, * as mockKey from './mock'
import commonApi, * as commonKey from './common'
import manageApi, * as manageKey from './data-manage'
import handleApi, * as handleKey from './data-handle'
import jiaojiApi, * as jiaojiKey from './data-jiaoji'
import overviewApi, * as overviewKey from './data-overview'
import transferApi, * as transferKey from './transfer'
import authApi, * as authKey from './auth'
import gaokaoApi, * as gaokaoKey from './gaokao'
import importApi, * as importKey from './import'
import analyseApi, * as analyseKey from './data-analyse'
import moreDataApi, * as moreDataKey from './moreData'
import contrastApi, * as contrastKey from './contrast'
import noticeApi, * as noticeKey from './data-notice'
import portraitVerificationApi, * as portraitVerificationKey from './portrait-verification'
import bigScreenApi, * as bigScreenKey from './bigScreen'
import hreportApi, * as hreportKey from './hreport'
import divisionApi, * as divisionKey from './division'
import versionApi, * as versionKey from './version'
import questionnaireApi, * as questionnaireKey from './questionnaire'
import baoDaoLinkApi, * as baoDaoLinkKey from './data-baodao'
import welcomeApi, * as welcomeKey from './welcome'
import logApi, * as logKey from './log'
import qualityreportApi, * as qualityreportKey from './quality-report'

// 导入数据发布模块
import helperApi, * as helperKey from './helper'
import releaseApi, * as releaseKey from './data-release'
const __DEV__ = process.env.NODE_ENV === 'development'

const { IMAGE_PATH } = require('../eol-api-domain')

const checkKey = apiKey => {
  if (__DEV__) {
    const values = apiKey.map(item => Object.values(item)).flat()
    const keys = apiKey.map(item => Object.keys(item)).flat()
    values.forEach((item, i) => {
      const repeatValue = values.slice(i + 1).find(k => item === k)
      const repeatKey = keys.slice(i + 1).find(k => keys[i] === k)
      if (repeatValue) {
        alert(`重复定义value:${repeatKey}`)
      }
      if (repeatKey && repeatKey !== 'default') {
        alert(`重复定义key:${repeatKey}`)
      }
    })
  }
  const api = {}
  apiKey.forEach(item => {
    Object.assign(api, item)
  })
  return api
}

export const API = checkKey([
  { IMAGE_PATH: IMAGE_PATH },
  loginKey,
  mockKey,
  commonKey,
  manageKey,
  analyseKey,
  handleKey,
  jiaojiKey,
  overviewKey,
  transferKey,
  authKey,
  importKey,
  gaokaoKey,
  moreDataKey,
  releaseKey,
  helperKey,
  contrastKey,
  noticeKey,
  portraitVerificationKey,
  bigScreenKey,
  hreportKey,
  divisionKey,
  versionKey,
  questionnaireKey,
  baoDaoLinkKey,
  welcomeKey,
  logKey,
  qualityreportKey,
])

const realUrl = {
  ...loginApi,
  ...mockApi,
  ...commonApi,
  ...manageApi,
  ...portraitVerificationApi,
  ...handleApi,
  ...jiaojiApi,
  ...overviewApi,
  ...transferApi,
  ...authApi,
  ...importApi,
  ...analyseApi,
  ...gaokaoApi,
  ...moreDataApi,
  ...releaseApi,
  ...helperApi,
  ...contrastApi,
  ...noticeApi,
  ...bigScreenApi,
  ...hreportApi,
  ...divisionApi,
  ...versionApi,
  ...questionnaireApi,
  ...baoDaoLinkApi,
  ...welcomeApi,
  ...logApi,
  ...qualityreportApi
}

export default realUrl
