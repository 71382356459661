import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { routes, RouteWithSubRoutes } from './router'
import { ConfigProvider, message } from 'antd'
import { Provider } from 'react-redux'
import store from './redux/store'
import DocumentTitle from 'react-document-title'
import { Loading, RouterListen } from './components/common'
import zhCN from 'antd/es/locale/zh_CN'
import 'moment/locale/zh-cn'
import './assets/style/common.scss'
import { useRouterInfo } from './hooks'

message.config({ top: 50, maxCount: 1 })
function App() {
  const { name } = useRouterInfo()
  const routerName = name ? `${name} - 招录信息管理系统` : '招录信息管理系统'
  return (
    <DocumentTitle title={routerName}>
      <Switch>
        {routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}
      </Switch>
    </DocumentTitle>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <Suspense fallback={<Loading />}>
        <Router>
          <RouterListen>
            <App />
          </RouterListen>
        </Router>
      </Suspense>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
)