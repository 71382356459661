import CryptoJS from 'crypto-js'
import md5 from 'js-md5'
import user from '../user'
class SignApi {

  constructor({ timestamp } = {}) {
    this.userName = user.getuserName() || ''
    this.token = user.getuserToken()
    this.timestamp = timestamp || Date.now()
    this.iv = md5(this.userName + this.token).slice(8, 24)
    this.key = md5(this.timestamp + this.userName + this.iv).slice(8, 24)
  }

  /** 加密函数 */
  encrypt(params = {}) {
    const aesSign = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(params)), this.key, {
      iv: CryptoJS.enc.Utf8.parse(this.iv),
      mode: CryptoJS.mode.CBC
    })
    return aesSign.toString()
  }

  /** 解密函数 */
  decode(text = '') {
    const result = CryptoJS.AES.decrypt(text, this.key, {
      iv: CryptoJS.enc.Utf8.parse(this.iv),
      mode: CryptoJS.mode.CBC,
    })
    return JSON.parse(result.toString(CryptoJS.enc.Utf8) || '{}')
  }
}

export default SignApi