
import { saveAs } from 'file-saver'
import EolAxios, { API } from '../axios'
import getRealUrl from '../axios/get-real-url'
import user from '../user'
import { message } from "antd"
import getInfo from '../assets/static/export-watermark.js'
/**
* @description : 获取URL参数
* @param {String} variable 需要查询的URL中的参数
*/


export function getQueryVariable(variable = '') {
  let query = decodeURIComponent(window.location.search.substring(1))
  let vars = query.split("&")
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=")
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return ''
}
// 获取地址栏参数（包括=号）
export function getQueryString(name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
  const r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}
// 获取平台
export const getPlatform = (callback) => {
  let platform = localStorage.getItem('platform')
  if (platform) {
    callback(platform)
  } else {
    EolAxios.dynamicRequest({
      path: API.commonConfig
    }).then(res => {
      if (!EolAxios.checkResponse({ res })) return
      localStorage.setItem('platform', res.platform)
      callback(res.platform)
    })
  }
}
/**
 * @description : 通用的下载文件方法，文件名可选
 * @param {string} fileId 文件id
 * @param {string} [fileName] 文件名（包含后缀）
 * @param {()=>void} [callback] 回调函数
 */
export const downloadFile = (fileId, fileName, callback) => {
  const { school: { IsExport, IsExportVerify } } = user.getuserInfo()
  const location = window.location.pathname
  let isXITONG = getInfo.XITONG.some(item => location.includes(item))
  let isRENXIANG = getInfo.RENXIANG.some(item => location.includes(item))
  const getDownloadUrl = () => {
    if (!fileName) {
      const userToken = user.getuserToken()
      window.open(`${getRealUrl(API.fileDownload)}?file_id=${fileId}&token=${userToken}`)
      callback?.()
      return
    }

    EolAxios.dynamicRequest({
      path: API.fileDownload,
      method: 'get',
      formData: { file_id: fileId },
      responseType: 'blob', // * 设置返回类型为blob
      timeout: 10 * 60 * 1000
    }).then((res) => {
      saveAs(res, fileName) // * 保存文件
      callback?.()
    })
  }
  const getMessage = () => {
    message.warning('您当前是试用版本，无法导出，请联系中国教育在线分站处理')
    callback?.()
    return
  }

  if (isXITONG && (IsExport === 1 || IsExportVerify === 1)) {
    getDownloadUrl()
  } else if (!isRENXIANG && IsExport === 1) {
    getDownloadUrl()
  } else if (!isRENXIANG && IsExport === 2) {
    getMessage()
  } else if (isRENXIANG && IsExportVerify === 1) {
    getDownloadUrl()
  } else if (isRENXIANG && IsExportVerify === 2) {
    getMessage()
  } else if (IsExport === 2 && IsExportVerify === 2) {
    getMessage()
  }
}

/**
 * @description 动态合并单元格
 * @param {Array} data 表格数据
 * @param {string[]} dataIndexList 字段名数组 字段值全部相同时才会合并 
 * @param {'colSpan'|'rowSpan'} type 类型 行/列
 * @returns {Array} 合并后的数据
 */
export const mergeTableCell = (data, dataIndexList, type) => {
  if (!Array.isArray(data)) {
    console.error(`data 必须是数组`)
    return data
  }

  return data
    .reduce((result, item) => {
      const key = dataIndexList.map(index => item[index]).join('-')
      if (!result.includes(key)) {
        result.push(key)
      }

      return result
    }, [])
    .reduce((result, name) => {
      const children = data.filter((item) => {
        const key = dataIndexList.map(index => item[index]).join('-')
        return key === name
      })
      result = [
        ...result,
        ...children.map((item, index) => ({
          ...item,
          [type]: index === 0 ? children.length : 0,
        })),
      ]

      return result
    }, [])
}
/**
 * **调起打印**
 * @param {File} file base64 pdf文件
 */
export const printBolb = (file, info, callback) => {
  const { page_size, page_height, page_width, rotation } = info
  const getPageWidth = () => {
    if (page_size === '自定义') {
      return `${page_width}mm`
    } else {
      if (rotation === '1') {
        return `${Math.min(Number(page_height), Number(page_width))}mm`
      } else {
        return `${Math.max(Number(page_height), Number(page_width))}mm`
      }
    }
  }
  const getPageHeight = () => {
    if (page_size === '自定义') {
      return `${page_height}mm`
    } else {
      if (rotation === '1') {
        return `${Math.max(Number(page_height), Number(page_width))}mm`
      } else {
        return `${Math.min(Number(page_height), Number(page_width))}mm`
      }
    }
  }
  const fileURL = URL.createObjectURL(file)
  let printIframe = document.querySelectorAll('.printIframe')
  if (printIframe && printIframe.length) {
    printIframe.forEach((node) => {
      if (node.parentNode) {
        node.parentNode.removeChild(node)
      }
    })
  }
  const iframe = document.createElement('iframe')
  iframe.className = 'printIframe'
  iframe.style.visibility = 'hidden'
  iframe.src = fileURL
  document.body.appendChild(iframe)
  iframe.contentWindow.focus()
  iframe.addEventListener('load', e => {
    if (navigator.userAgent.indexOf('Firefox') === -1) {
      const cssText = document.createTextNode(`@media print { @page {size:${getPageWidth()} ${getPageHeight()};border: 0;padding: 0cm;margin: 0cm}}`)
      const style = document.createElement("style")
      style.setAttribute("type", "text/css")
      style.appendChild(cssText)
      iframe.contentWindow.document.getElementsByTagName('head')[0].appendChild(style)
    }
    callback()
    iframe.contentWindow.print()
  })
}
/**
 * **调起打印**
 * @param {File} file base64 pdf文件
 */
export const print = (file, info, callback) => {
  const { page_size, page_height, page_width, rotation } = info
  const getPageWidth = () => {
    if (page_size === '自定义') {
      return `${page_width}mm`
    } else {
      if (rotation === '1') {
        return `${Math.min(Number(page_height), Number(page_width))}mm`
      } else {
        return `${Math.max(Number(page_height), Number(page_width))}mm`
      }
    }
  }
  const getPageHeight = () => {
    if (page_size === '自定义') {
      return `${page_height}mm`
    } else {
      if (rotation === '1') {
        return `${Math.max(Number(page_height), Number(page_width))}mm`
      } else {
        return `${Math.min(Number(page_height), Number(page_width))}mm`
      }
    }
  }
  const byteCharacters = atob(file.split('base64,')[1])
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], { type: 'application/pdf' })
  const fileURL = URL.createObjectURL(blob)
  let printIframe = document.querySelectorAll('.printIframe')
  if (printIframe && printIframe.length) {
    printIframe.forEach((node) => {
      if (node.parentNode) {
        node.parentNode.removeChild(node)
      }
    })
  }
  const iframe = document.createElement('iframe')
  iframe.className = 'printIframe'
  iframe.style.visibility = 'hidden'
  iframe.src = fileURL
  document.body.appendChild(iframe)
  iframe.contentWindow.focus()
  iframe.addEventListener('load', e => {
    if (navigator.userAgent.indexOf('Firefox') === -1) {
      const cssText = document.createTextNode(`@media print { @page {size:${getPageWidth()} ${getPageHeight()};border: 0;padding: 0cm;margin: 0cm}}`)
      const style = document.createElement("style")
      style.setAttribute("type", "text/css")
      style.appendChild(cssText)
      iframe.contentWindow.document.getElementsByTagName('head')[0].appendChild(style)
    }
    callback()
    iframe.contentWindow.print()
  })
}
/**
 * **等待一段时间**
 * ```js
 * // 例：
 * await wait(1000);
 * ```
 * @param {number} ms 毫秒数
 */
export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const isNumberBrowser = () => {
  function checkIeForNum() {
    return ((navigator.msPointerEnabled === undefined ? true : navigator.msPointerEnabled)
      && (navigator.msDoNotTrack === 1 || window.doNotTrack === 1)
      && ((Number(window.screenX) ? (window.screenLeft - window.screenX !== 8) : false)
        || ((navigator.userAgent.indexOf('MSIE 7.0') !== -1 || navigator.userAgent.indexOf('MSIE 8.0') !== -1) && console.count === undefined)))
  }
  function checkChromeForNum() {
    let uas = navigator.userAgent.split(' '),
      result = false
    if (uas[uas.length - 1].indexOf('Safari') === -1) {
      return result
    }
    for (let key in navigator.plugins) {
      if (navigator.plugins[key].filename === 'np-mswmp.dll') {
        return !result
      }
    }
    return result
  }
  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return checkChromeForNum()
  } else {
    return checkIeForNum()
  }
}
export const is360x32 = () => {
  const chromeVersionRegex = /Chrome\/([\d.]+)/
  const match = navigator.userAgent.match(chromeVersionRegex)  
  if (navigator.userAgent.indexOf('x64') > -1) {
    return false
  } else {
    if (match && match[1]) {
      if (Number(match[1].split('.')[0]) < 100) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
}
export * from './validator-regexp'
export * from './validator-account'
export * from './validator-array'
export * from './repeatArrayItem'
export * from './import'