/**
 * @Author: 郝家奎
 * @Date: 2023-02-08 10:23:26
 * @LastEditTime: 2023-02-08 10:23:33
 * @LastEditors: 郝家奎
 * @FilePath: \ddp_pc\src\axios\api\moreData.js
 * @Description: 更多数据
 */
const { api, } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
// 专业分省分析
export const moreDataSpecialSubPro = 'moreDataSpecialSubPro'
// 更多统计列表
export const moreDataStatisticsLists = 'moreDataStatisticsLists'
// 各省分专业分析
export const moreDataProSubSpecial = 'moreDataProSubSpecial'
setRealUrl(`专业分省分析`, moreDataSpecialSubPro, () => api + `/school/appschool/moreAnalyse/specialSubPro`)
setRealUrl(`更多统计列表`, moreDataStatisticsLists, () => api + `/school/appschool/moreAnalyse/statisticsLists`)
setRealUrl(`各省分专业分析`, moreDataProSubSpecial, () => api + `/school/appschool/moreAnalyse/proSubSpecial`)
export default realUrl