/*
 * @Author: 王松
 * @Descripttion: 通知书模块
 * @Date: 2022-12-27 09:13:30
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
// 通知书编号
export const numberList = 'numberList'
export const numberUseLastRule = 'numberUseLastRule'
export const numberGetEnrollType = 'numberGetEnrollType'
export const numberHistoryRuleLists = 'numberHistoryRuleLists'
export const numberDelHistoryRule = 'numberDelHistoryRule'
export const numberResetNoticeNum = 'numberResetNoticeNum'
export const numberBatchDelNoticeNum = 'numberBatchDelNoticeNum'
export const numberProduceNoticeNumber = 'numberProduceNoticeNumber'
export const numberDataImport = 'numberDataImport'
export const numberNoticeDataUpload = 'numberNoticeDataUpload'
setRealUrl(`通知书编号-生成学生列表`, numberList, () => api + `/notice/appnotice/number/lists`)
setRealUrl(`通知书编号-获取招生类型`, numberGetEnrollType, () => api + `/notice/appnotice/number/getEnrollType`)
setRealUrl(`通知书编号-使用上一次的编号规则`, numberUseLastRule, () => api + `/notice/appnotice/number/useLastRule`)
setRealUrl(`通知书编号-历史规则列表`, numberHistoryRuleLists, () => api + `/notice/appnotice/number/historyRuleLists`)
setRealUrl(`通知书编号-删除历史规则`, numberDelHistoryRule, () => api + `/notice/appnotice/number/delHistoryRule`)
setRealUrl(`通知书编号-重置通知书编号`, numberResetNoticeNum, () => api + `/notice/appnotice/number/resetNoticeNum`)
setRealUrl(`通知书编号-删除通知书编号`, numberBatchDelNoticeNum, () => api + `/notice/appnotice/number/batchDelNoticeNum`)
setRealUrl(`通知书编号-生成通知书编号`, numberProduceNoticeNumber, () => api + `/notice/appnotice/number/produceNoticeNumber`)
setRealUrl(`通知书编号-通知书编号导入`, numberDataImport, () => api + `/notice/appnotice/number/dataImport`)
setRealUrl(`通知书编号-通知书文件导入`, numberNoticeDataUpload, () => api + `/notice/file/noticeDataUpload`)

// 录取进程
// /enrollProcess/enroll/process/list
export const enrollProcessTableList = 'enrollProcessTableList'
export const enrollProcessYearList = 'enrollProcessYearList'
export const enrollProcessLink = 'enrollProcessLink'
export const enrollProcessConfigs = 'enrollProcessConfigs'
export const enrollProcessGetSettings = 'enrollProcessGetSettings'
export const enrollProcessSaveSettings = 'enrollProcessSaveSettings'
export const processgetLinkSet = 'processgetLinkSet'
export const processlinkSet = 'processlinkSet'
export const processDelete = 'processDelete'
export const processUpload = 'processUpload'
setRealUrl(`录取进程-表格列表`, enrollProcessTableList, () => api + `/enrollProcess/enroll/process/list`)
setRealUrl(`录取进程-年份列表`, enrollProcessYearList, () => api + `/enrollProcess/enroll/process/year`)
setRealUrl(`录取进程-大屏链接`, enrollProcessLink, () => api + `/enrollProcess/enroll/process/link`)
setRealUrl(`录取进程-筛选配置与地图`, enrollProcessConfigs, () => api + `/enrollProcess/enroll/process/config`)
setRealUrl(`录取进程-获取设置`, enrollProcessGetSettings, () => api + `/enrollProcess/enroll/process/getSet`)
setRealUrl(`录取进程-保存配置`, enrollProcessSaveSettings, () => api + `/enrollProcess/enroll/process/set`)
setRealUrl(`获取连接设置`, processgetLinkSet, () => api + `/enrollProcess/enroll/process/getLinkSet`)
setRealUrl(`保存连接设置`, processlinkSet, () => api + `/enrollProcess/enroll/process/linkSet`)
setRealUrl(`自定义数据删除`, processDelete, () => api + `/enrollProcess/enroll/process/delete`)
setRealUrl(`自定义数据上传`, processUpload, () => api + `/school/file/processUpload`)
// 录取进程 end

// 通知书模板
export const noticeTepList = 'noticeTepList'
export const noticeTepSetDefault = 'noticeTepSetDefault'
export const noticeTepAdd = 'noticeTepAdd'
export const noticeTepDeleteTemplate = 'noticeTepDeleteTemplate'
export const noticeTepClone = 'noticeTepClone'
export const noticeTepUpdate = 'noticeTepUpdate'
export const noticeTepContent = 'noticeTepContent'
export const noticeTemplateUploadImg = 'noticeTemplateUploadImg'
export const noticeTemplatePreview = 'noticeTemplatePreview'
setRealUrl(`通知书-模板列表`, noticeTepList, () => api + `/notice/appnotice/template/lists`)
setRealUrl(`通知书-设置默认`, noticeTepSetDefault, () => api + `/notice/appnotice/template/setDefault`)
setRealUrl(`通知书-新建`, noticeTepAdd, () => api + `/notice/appnotice/template/add`)
setRealUrl(`通知书-删除`, noticeTepDeleteTemplate, () => api + `/notice/appnotice/template/deleteTemplate`)
setRealUrl(`通知书-复制`, noticeTepClone, () => api + `/notice/appnotice/template/clone`)
setRealUrl(`通知书-编辑模板`, noticeTepUpdate, () => api + `/notice/appnotice/template/update`)
setRealUrl(`通知书-模板详情`, noticeTepContent, () => api + `/notice/appnotice/template/oneContent`)
setRealUrl(`通知书-上传背景图或自定义图片`, noticeTemplateUploadImg, () => api + `/notice/file/templateUploadImg`)
setRealUrl(`通知书-预览`, noticeTemplatePreview, () => api + `/notice/appnotice/print/preview`)
// 通知书打印
export const noticePrintLists = 'noticePrintLists'
export const noticePrintConfig = 'noticePrintConfig'
export const noticePrintUpload = 'noticePrintUpload'
export const noticeSetPrintStatus = 'noticeSetPrintStatus'
export const noticePrintEdit = 'noticePrintEdit'
export const noticeMissingData = 'noticeMissingData'
export const noticeCreatePdf = 'noticeCreatePdf'
export const noticeBatchPrinting = 'noticeBatchPrinting'
export const noticeBatchPrintings = 'noticeBatchPrintings'
export const noticeBatchGeneratePdf = 'noticeBatchGeneratePdf'
export const printNoticeDownload = 'printNoticeDownload'
export const printNoticeDownloadDel = 'printNoticeDownloadDel'
export const printDownloadddd = 'printDownloadddd'
export const printGetPdfProcess = 'printGetPdfProcess'
export const printGetPdfBinaryStream = 'printGetPdfBinaryStream'
export const printGetPdfCancel = 'printGetPdfCancel'
setRealUrl(`通知书打印-列表`, noticePrintLists, () => api + `/notice/appnotice/print/lists`)
setRealUrl(`通知书打印-搜索配置`, noticePrintConfig, () => api + `/notice/appnotice/print/config`)
setRealUrl(`通知书打印-文件导入`, noticePrintUpload, () => api + `/notice/file/noticeDataUpload`)
setRealUrl(`通知书打印-设置状态`, noticeSetPrintStatus, () => api + `/notice/appnotice/print/setPrintStatus`)
setRealUrl(`通知书打印-编辑`, noticePrintEdit, () => api + `/notice/appnotice/print/edit`)
setRealUrl(`通知书打印-缺失列表`, noticeMissingData, () => api + `/notice/appnotice/print/missingData`)
setRealUrl(`通知书打印-打印`, noticeCreatePdf, () => api + `/notice/appnotice/print/createPdf`)
setRealUrl(`通知书打印-批量打印`, noticeBatchPrinting, () => api + `/notice/appnotice/print/batchPrinting`)
setRealUrl(`通知书打印-新批量打印`, noticeBatchPrintings, () => api + `/notice/appnotice/print/batchPrintings`)
setRealUrl(`通知书打印-批量生成`, noticeBatchGeneratePdf, () => api + `/notice/appnotice/print/generatePdf`)
setRealUrl(`通知书打印-生成列表`, printNoticeDownload, () => api + `/notice/appnotice/print/noticeDownload`)
setRealUrl(`通知书打印-生成删除`, printNoticeDownloadDel, () => api + `/notice/appnotice/print/noticeDownloadDel`)
setRealUrl(`通知书打印-下载`, printDownloadddd, () => api + `/notice/appnotice/print/download`)
setRealUrl(`通知书打印-获取批量打印进度`, printGetPdfProcess, () => api + `/notice/appnotice/print/getPdfProcess`)
setRealUrl(`通知书打印-获取pdf打印流`, printGetPdfBinaryStream, () => api + `/notice/appnotice/print/getPdfBinaryStream`)
setRealUrl(`通知书打印-批量打印取消`, printGetPdfCancel, () => api + `/notice/appnotice/print/getPdfCancel`)
// 通知书数据流转
export const dataLists = 'dataLists'
export const dataConfig = 'dataConfig'
export const dataEdit = 'dataEdit'
export const dataPreview = 'dataPreview'
export const dataExpress = 'dataExpress'
export const dataGetTemplate = 'dataGetTemplate'
export const dataSetTemplate = 'dataSetTemplate'
setRealUrl(`通知书数据流转列表`, dataLists, () => api + `/notice/appnotice/data/lists`)
setRealUrl(`快递单号、银行卡号筛选`, dataConfig, () => api + `/notice/appnotice/data/config`)
setRealUrl(`通知书数据流转-编辑`, dataEdit, () => api + `/notice/appnotice/data/edit`)
setRealUrl(`通知书数据流转-通知书模板预览`, dataPreview, () => api + `/notice/appnotice/data/preview`)
setRealUrl(`通知书数据流转-查看物流`, dataExpress, () => api + `/notice/appnotice/data/express`)
setRealUrl(`通知书数据流转-设置通知书模板查看`, dataGetTemplate, () => api + `/notice/appnotice/data/getTemplate`)
setRealUrl(`通知书数据流转-保存通知书模板`, dataSetTemplate, () => api + `/notice/appnotice/data/setTemplate`)

export default realUrl