import CachePorxy from './cache-porxy'
import getRealUrl from './get-real-url'
import axios from 'axios'
import SignApi from './sign-api'
import { message, Modal } from 'antd'
import user from '../user'
const { YUN_PATH } = require('./eol-api-domain')
const loginOut = () => {
  user.clearUserStorage()
  if (window.location.hash.indexOf('zjzw.cn') > -1 || window.location.hash.indexOf('gaokao.cn') > -1) {
    window.location.href = `${YUN_PATH}from=${window.location.href}`
  } else {
    window.location.href = window.location.origin + '/login'
  }
}

export const RESPONSE_TYPE = {
  TIMEOUT_ERROR: 'TIMEOUT_ERROR',     // 超时
  CANCEL_ERROR: 'CANCEL_ERROR',       // 因防抖或者cancel取消请求
  SERVER_ERROR: 'SERVER_ERROR',       // 接口成功，服务器返回错误状态
  NETWORK_ERROR: 'NETWORK_ERROR',     // 接口请求失败，网络错误
  AUTHORITY_ERROR: 'AUTHORITY_ERROR', // 鉴权失败
  TOKEN_ERROR: 'TOKEN_ERROR',         // token失效
  OTHER_LOGIN_ERROR: 'OTHER_LOGIN_ERROR', // 其他人登录
  USER_ERROR: 'USER_ERROR',           // 显示给用户看的错误信息
}

/**
*
* @author: 田源
* @date : 2021-08-13 15:35
* @description: 请求类
*/
class EolAxios {

  #porxy = new CachePorxy()
  #timeout = 60000
  #modalStatus = false // modal的显示状态

  /**
   * @description 不符合公司接口规范的请求
   * @param {string} path 请求路径
   * @param {String} method 请求方法 post | get 默认get
   * @param {Array} params 静态请求的链接中拼接的参数 array
   * @param {Object} formData 动态请求的参数
   * @param {string} responseType 返回数据类型，不传默认json
   * @param {Boolean} debounce 当前接口是否需要开启防抖，默认true
   * @param {Boolean} throttle 当前接口是否需要开启节流，默认false
   * 
   */
  async otherRequest({ path, method = 'get', params = [], formData, responseType, debounce, throttle }) {
    if (method === 'get') {
      formData = JSON.stringify(formData)
    }
    return this.#sendRequest({
      url: getRealUrl(path, params),
      method,
      formData,
      responseType,
      debounce,
      throttle,
      otherRequest: true
    })
  }

  /**
   * @description 静态接口请求方法
   * @param {string} path 请求路径
   * @param {String} method 请求方法 post | get 默认get
   * @param {Array} params 静态请求的链接中拼接的参数 array
   * @param {Boolean} needCache 当前接口是否需要缓存，默认为false
   * @param {Boolean} debounce 当前接口是否需要开启防抖，默认true
   * @param {Boolean} throttle 当前接口是否需要开启节流，默认false
   */
  async staticRequest({ path, method = 'get', params = [], needCache, debounce, throttle }) {
    return this.#sendRequest({
      url: getRealUrl(path, params),
      method,
      formData: '',
      needCache,
      debounce,
      throttle,
    })
  }

  /**
   * @description 动态接口请求方法
   * @param {string} path 请求路径
   * @param {String} method 请求方法 post | get 默认post
   * @param {Object} formData 动态请求的参数
   * @param {string} responseType 返回数据类型，不传默认json
   * @param {Object} query 动态请求的链接中需要拼接的参数
   * @param {Boolean} needEncrypt 当前接口是否需要加密，默认为false
   * @param {Boolean} needCache 当前接口是否需要缓存，默认为true
   * @param {Boolean} debounce 当前接口是否需要开启防抖，默认true
   * @param {Boolean} throttle 当前接口是否需要开启节流，默认false
   * @param {number}  timeout  当前接口超时时长，默认 8000 ms
   */
  async dynamicRequest({ path, method = 'post', formData = {}, query = {}, responseType, needEncrypt, needCache, debounce, throttle, timeout }) {
    this.#timeout = timeout || this.#timeout
    let url = getRealUrl(path)

    // 处理加密的接口
    const signApi = new SignApi()
    if (needEncrypt) {
      formData = {
        data: signApi.encrypt(formData),
        lock: '1'
      }
    }

    // 请求
    const response = await this.#sendRequest({
      url: handleUrl({ url, method, formData, query }),
      method,
      formData,
      responseType,
      needCache,
      debounce,
      throttle,
      headers: {
        'X-TC-Timestamp': signApi.timestamp
      }
    })
    // 解密
    if (needEncrypt && !response.errStatus) {
      return signApi.decode(response)
    }
    return response
  }

  async #sendRequest({
    url, method, formData = {}, responseType, headers, needCache = false,
    debounce, throttle, otherRequest = false
  }) {
    if (typeof throttle === 'undefined' && typeof debounce === 'undefined') {
      debounce = true
    }
    const cacheKey = url + JSON.stringify(formData)
    const cacheRes = this.#porxy.getCache(cacheKey)
    if (cacheRes && needCache) {
      return cacheRes
    }

    // 防抖模式下取消前一次请求
    if (debounce && this.#porxy.pendingCache(cacheKey)) {
      this.#porxy.getPendingCache(cacheKey).cancel('axios canceled request')
    }

    // 节流模式下直接抛一个reject出去
    if (throttle && this.#porxy.pendingCache(cacheKey)) {
      return Promise.reject('节流模式取消多次请求')
    }

    return new Promise(resolve => {
      setTimeout(() => {
        otherRequest ?
          resolve(this.#otherAxios({ url, method, formData, responseType, cacheKey })) :
          resolve(this.#axios({ headers, url, method, formData, responseType, cacheKey, needCache }))
      })
    })
  }

  /** 非公司内部规范的接口请求 */
  async #otherAxios({ url, method, formData, cacheKey }) {
    const source = axios.CancelToken.source()
    this.#porxy.addPendingCache(cacheKey, source)

    return await axios({
      url: url,
      method: method,
      data: formData,
      timeout: this.#timeout,
      cancelToken: source.token
    })
      .then(response => response.data)
      .catch(res => {
        return {
          message: res + "",
          errStatus: RESPONSE_TYPE.NETWORK_ERROR
        }
      })
      .then(res => {
        this.#porxy.deletePendingCache(cacheKey)
        return res
      })
  }

  /** 符合公司内部规范的请求 */
  async #axios({ headers, url, method, formData, responseType, cacheKey, needCache }) {
    const source = axios.CancelToken.source()
    this.#porxy.addPendingCache(cacheKey, source)

    try {
      const res = await axios({
        url: url,
        method: method,
        data: formData,
        responseType,
        timeout: this.#timeout,
        cancelToken: source.token,
        headers
      })

      // 返回二进制文件
      if (responseType === 'blob') {
        return res.data
      }
      // 请求成功，返回数据
      if (res.data.code === 0) {
        needCache && this.#porxy.setCache(cacheKey, res.data.data)
        return res.data.data
      }
      // 抛异常
      throw { serverError: res.data } // eslint-disable-line
    } catch (err) {
      let errorResult = { errStatus: true }
      // ! 服务器200状态码下的code非0
      if (err.serverError) {
        switch (err.serverError.code) {
          case 401:
            errorResult.errStatus = RESPONSE_TYPE.TOKEN_ERROR
            break
          case 460:
            errorResult.errStatus = RESPONSE_TYPE.AUTHORITY_ERROR
            break
          case 470:
            errorResult.errStatus = RESPONSE_TYPE.OTHER_LOGIN_ERROR
            break
          default:
            if (err.serverError.code >= 10000) {
              errorResult.errStatus = RESPONSE_TYPE.USER_ERROR
            } else {
              errorResult.errStatus = RESPONSE_TYPE.SERVER_ERROR
            }
            break
        }
        errorResult.message = err.serverError.message
        errorResult.code = err.serverError.code
      }
      // ! axios给的服务器非200状态码
      if (err.response) {
        errorResult.message = err.response.statusText
        errorResult.code = err.response.status
        errorResult.errStatus = RESPONSE_TYPE.NETWORK_ERROR
      }
      // ! 触发防抖、cancel造成的接口取消
      if (err.toString() === 'Cancel: axios canceled request') {
        errorResult.message = '请求取消'
        errorResult.errStatus = RESPONSE_TYPE.CANCEL_ERROR
      }
      // ! 接口超时
      if (err.toString() === `Error: timeout of ${this.#timeout}ms exceeded`) {
        errorResult.message = '请求超时'
        errorResult.errStatus = RESPONSE_TYPE.TIMEOUT_ERROR
      }
      return errorResult
    } finally {
      this.#porxy.deletePendingCache(cacheKey)
    }
  }

  /** 取消当前未返回的请求, 处理react认为setState存在的内存泄露bug */
  cancel(path) {
    if (path) {
      const url = getRealUrl(path)
      this.#porxy.cancelPending(url)
    } else {
      this.#porxy.cancelPending()
    }
  }

  /** 检查当前结果状态 */
  checkResponse({ res, text, showWarning = true }) {
    if (!res?.errStatus) return true

    switch (res.errStatus) {
      case RESPONSE_TYPE.OTHER_LOGIN_ERROR:
        if (!this.#modalStatus) {
          this.#modalStatus = true
          Modal.info({
            title: '温馨提示',
            okText: '确定',
            onOk: () => {
              this.#modalStatus = false
              loginOut()
            },
            content: '您的账号已经在别的地方登录！'
          })
        }
        break
      case RESPONSE_TYPE.TOKEN_ERROR:
        if (!this.#modalStatus) {
          this.#modalStatus = true
          Modal.info({
            title: '温馨提示',
            okText: '确定',
            onOk: () => {
              this.#modalStatus = false
              loginOut()
            },
            content: '登录状态已失效，请重新登录'
          })
        }

        break
      case RESPONSE_TYPE.USER_ERROR:
      case RESPONSE_TYPE.AUTHORITY_ERROR:
        showWarning && message.warning(res.message)
        break
      case RESPONSE_TYPE.TIMEOUT_ERROR:
        showWarning && message.warning('接口请求超时，请刷新重试')
        break
      default:
        text && res.errStatus !== RESPONSE_TYPE.CANCEL_ERROR && showWarning && message.warning(text)
    }
    return false
  }
}

// 处理url拼接数据
function handleUrl({ url, method, formData, query }) {

  const parseQuery = str => str.indexOf('?') !== -1 ? '&' : '?'

  const userToken = user.getuserToken()

  if (userToken) {
    url += `${parseQuery(url)}token=${userToken}`
  }

  // 如果方法是get,并且拥有formData参数的情况下，添加参数至url
  if (method === 'get' && Object.keys(formData).length) {
    for (let key in formData) {
      url += `${parseQuery(url)}${key}=${formData[key]}`
    }
  }

  // 如果方法是post,并且拥有query参数的情况下，添加参数至url
  if (method === 'post' && Object.keys(query).length) {
    for (let key in query) {
      url += `${parseQuery(url)}${key}=${query[key]}`
    }
  }

  return url
}

const eolAxios = new EolAxios()

export default eolAxios 