/**
 * @Author: 郝家奎
 * @Date: 2023-04-03 10:23:26
 * @LastEditTime:  2023-04-03 10:23:26
 * @LastEditors: 郝家奎
 * @Description: 人像核验
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

// 人像核验
export const creatQRTableList = 'creatQRTableList'
setRealUrl(`二维码生成-列表`, creatQRTableList, () => api + `/verify/appverify/setting/lists`)
export const creatQRSettinglogo = 'creatQRSettinglogo'
setRealUrl(`替换logo`, creatQRSettinglogo, () => api + `/verify1/appverify/setting/logo`)
export const creatQRPreviewLogo = 'creatQRPreviewLogo'
setRealUrl(`预览logo`, creatQRPreviewLogo, () => api + `/verify/appverify/setting/preview`)
export const creatQRExportLogos = 'creatQRExportLogos'
setRealUrl(`批量导出logo`, creatQRExportLogos, () => api + `/verify/appverify/setting/download`)
export const creatQRSaveCheckSettings = 'creatQRSaveCheckSettings'
setRealUrl(`保存核验设置`, creatQRSaveCheckSettings, () => api + `/verify/appverify/setting/setup`)
export const creatQRGetCheckSettings = 'creatQRGetCheckSettings'
setRealUrl(`获取核验设置`, creatQRGetCheckSettings, () => api + `/verify/appverify/setting/getSet`)
export const creatQRResetQR = 'creatQRResetQR'
setRealUrl(`初始化重置二维码`, creatQRResetQR, () => api + `/verify/appverify/setting/resetCampus`)
export const creatQRResetBigScreen = 'creatQRResetBigScreen'
setRealUrl(`重置大屏连接`, creatQRResetBigScreen, () => api + `/verify/appverify/setting/resetLink`)
export const creatQRHasResetCheckSettings = 'creatQRHasResetCheckSettings'
setRealUrl(`是否设置过核验配置`, creatQRHasResetCheckSettings, () => api + `/verify/appverify/setting/hasSet`)
export const creatQRBaseConfigs = 'creatQRBaseConfigs'
setRealUrl(`大屏连接、logo、是否设置核验配置、学院下拉等`, creatQRBaseConfigs, () => api + `/verify/appverify/setting/config`)


export const uploadVerifyFile = 'uploadVerifyFile'
setRealUrl(`上传文件`, uploadVerifyFile, () => api + `/school/file/verify`)
// 核验查询
export const verifyCampusYear = 'verifyCampusYear'
setRealUrl(`学院维度-年份下拉`, verifyCampusYear, () => api + `/verify/appverify/campus/year`)
export const verifyCampusConfig = 'verifyCampusConfig'
setRealUrl(`学院维度-学院下拉`, verifyCampusConfig, () => api + `/verify/appverify/campus/config`)
export const verifyCampusInfo = 'verifyCampusInfo'
setRealUrl(`学院维度-数据总览`, verifyCampusInfo, () => api + `/verify/appverify/campus/info`)
export const verifyCampusLists = 'verifyCampusLists'
setRealUrl(`学院维度-列表`, verifyCampusLists, () => api + `/verify/appverify/campus/lists`)
export const verifyCampusGetSet = 'verifyCampusGetSet'
setRealUrl(`学院维度-获取报告设置`, verifyCampusGetSet, () => api + `/verify/appverify/campus/getSet`)
export const verifyCampusSetup = 'verifyCampusSetup'
setRealUrl(`学院维度-保存报告设置`, verifyCampusSetup, () => api + `/verify/appverify/campus/setUp`)
export const verifyCampusLogo = 'verifyCampusLogo'
setRealUrl(`学院维度-替换logo`, verifyCampusLogo, () => api + `/verify/appverify/campus/setting/logo`)
export const verifyCampusHasSet = 'verifyCampusHasSet'
setRealUrl(`学院维度-是否开启水印印章`, verifyCampusHasSet, () => api + `/verify/appverify/campus/hasSet`)
export const verifyCampusReport = 'verifyCampusReport'
setRealUrl(`学院维度-生成报告`, verifyCampusReport, () => api + `/verify/appverify/campus/report`)
export const totalReportPdf = 'totalReportPdf'
setRealUrl(`总体核验报告-生成pdf`, totalReportPdf, () => api + `/verify/appverify/campus/pdf`)

export const verifyStudentsConfig = 'verifyStudentsConfig'
setRealUrl(`学生维度-筛选配置`, verifyStudentsConfig, () => api + `/verify/appverify/students/config`)
export const verifyStudentsInfo = 'verifyStudentsInfo'
setRealUrl(`学生维度-描述总览`, verifyStudentsInfo, () => api + `/verify/appverify/students/info`)
export const verifyStudentsLists = 'verifyStudentsLists'
setRealUrl(`学生维度-列表`, verifyStudentsLists, () => api + `/verify/appverify/students/lists`)
export const verifyStudentsOneReset = 'verifyStudentsOneReset'
setRealUrl(`学生维度-一键重置`, verifyStudentsOneReset, () => api + `/verify/appverify/students/oneReset`)
export const verifyStudentsDownStaticFile = 'verifyStudentsDownStaticFile'
setRealUrl(`学生维度-批量重置--模板下载`, verifyStudentsDownStaticFile, () => api + `/static/down_static_file`)
export const verifyStudentsUpload = 'verifyStudentsUpload'
setRealUrl(`学生维度-批量重置--上传模板`, verifyStudentsUpload, () => api + `/verify1/appverify/students/upload`)
export const verifyStudentsResetLists = 'verifyStudentsResetLists'
setRealUrl(`学生维度-批量重置--学生列表`, verifyStudentsResetLists, () => api + `/verify/appverify/students/resetLists`)
export const verifyStudentsBatchReset = 'verifyStudentsBatchReset'
setRealUrl(`学生维度-批量重置--重置`, verifyStudentsBatchReset, () => api + `/verify/appverify/students/batchReset`)
export const verifyStudentsReset = 'verifyStudentsReset'
setRealUrl(`学生维度-单个重置`, verifyStudentsReset, () => api + `/verify/appverify/students/reset`)
export const verifyStudentsStudentsInfo = 'verifyStudentsStudentsInfo'
setRealUrl(`学生维度-人工审核获取图片等隐藏信息`, verifyStudentsStudentsInfo, () => api + `/verify/appverify/students/studentsInfo`)
export const verifyStudentsLog = 'verifyStudentsLog'
setRealUrl(`学生维度-人工审核获取日志`, verifyStudentsLog, () => api + `/verify/appverify/students/verifyLog`)
export const verifyStudentsModifyVerify = 'verifyStudentsModifyVerify'
setRealUrl(`学生维度-人工审核确认`, verifyStudentsModifyVerify, () => api + `/verify/appverify/students/modifyVerify`)
export const verifyStudentsPhoto = 'verifyStudentsPhoto'
setRealUrl(`学生维度-替换照片`, verifyStudentsPhoto, () => api + `/verify1/appverify/students/photo`)
export const verifyStudentsGetSet = 'verifyStudentsGetSet'
setRealUrl(`生成报告--获取核验设置`, verifyStudentsGetSet, () => api + `/verify/appverify/students/getSet`)
export const verifyStudentsSetUp = 'verifyStudentsSetUp'
setRealUrl(`生成报告--保存核验设置`, verifyStudentsSetUp, () => api + `/verify/appverify/students/setUp`)
export const verifyStudentsTemplate = 'verifyStudentsTemplate'
setRealUrl(`生成报告--获取模板`, verifyStudentsTemplate, () => api + `/verify/appverify/students/template`)
export const verifyStudentsCreatePdf = 'verifyStudentsCreatePdf'
setRealUrl(`生成报告--生成`, verifyStudentsCreatePdf, () => api + `/verify/appverify/students/createPdf`)
export const verifyStudentsCreateLists = 'verifyStudentsCreateLists'
setRealUrl(`生成报告--生成列表`, verifyStudentsCreateLists, () => api + `/verify/appverify/students/createLists`)
export const verifyStudentsLeftNum = 'verifyStudentsLeftNum'
setRealUrl(`核验剩余次数查询`, verifyStudentsLeftNum, () => api + `/verify/appverify/remain/leftNum`)
export const verifyStudentsStatistics = 'verifyStudentsStatistics'
setRealUrl(`核验次数查询`, verifyStudentsStatistics, () => api + `/verify/appverify/remain/statistics`)
export const verifyStudentsReportDownload = 'verifyStudentsReportDownload'
setRealUrl(`生成报告--下载`, verifyStudentsReportDownload, () => api + `/verify/appverify/students/download`)
export const verifyStudentsReportDelete = 'verifyStudentsReportDelete'
setRealUrl(`生成报告--删除`, verifyStudentsReportDelete, () => api + `/verify/appverify/students/del`)
export const verifyStudentsReportBatchReset = 'verifyStudentsReportBatchReset'
setRealUrl(`批量重置--重置状态`, verifyStudentsReportBatchReset, () => api + `/verify/appverify/students/batchResetStatus`)
export default realUrl
